import { render, staticRenderFns } from "./StockInvoiceStakeholderPallet.vue?vue&type=template&id=92e6c254&scoped=true&"
import script from "./StockInvoiceStakeholderPallet.vue?vue&type=script&lang=js&"
export * from "./StockInvoiceStakeholderPallet.vue?vue&type=script&lang=js&"
import style0 from "./StockInvoiceStakeholderPallet.vue?vue&type=style&index=0&id=92e6c254&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e6c254",
  null
  
)

export default component.exports