<template>
  <div>
    <div class="text-right">
      <v-btn
        v-if="invoice.stockInvoiceTypeId == 1"
        color="btn-success"
        class="btn btn-light-success font-weight-bolder mr-2 mt-3"
        @click="getInvoicePdf()"
      >
        <i class="flaticon-download"></i>
        {{ $t("DOWNLOAD_PDF") }}
      </v-btn>
    </div>
    <v-col>
      <v-card v-show="!tempIsPageLoading">
        <v-col sm="12">
          <h1 class="text-center">
            <template v-if="invoice.stockInvoiceDocumentTypeId == 3">{{
              $t("CREDIT_DOCUMENT")
            }}</template>
            <template v-else-if="invoice.stockInvoiceDocumentTypeId == 4">{{
              $t("DEBIT_DOCUMENT")
            }}</template>
            <template v-else-if="invoice.stockInvoiceDocumentTypeId == 2">{{
              $t("CUSTOMS_DOCUMENT")
            }}</template>
            <template v-else>{{ $t("INVOICE") }}</template>
          </h1>
          <p class="invoice_number">
            {{ invoice.invoiceNumber }}<br />{{
              invoice.issueDate | formatDate
            }}
          </p>
        </v-col>
        <v-row>
          <v-col sm="6">
            <StockInvoiceRecipientIssuerData
              :selectedInvoiceId="selectedInvoiceId"
              :data.sync="invoice.issuerData"
              :heading="$t('ISSUER_DATA')"
              :source="'issuer'"
              :isPreviewMode="isPreviewMode"
            ></StockInvoiceRecipientIssuerData>
          </v-col>
          <v-col sm="6">
            <StockInvoiceRecipientIssuerData
              :selectedInvoiceId="selectedInvoiceId"
              :data.sync="invoice.recipientData"
              :heading="$t('RECIPIENT_DATA')"
              :source="'recipient'"
              :isPreviewMode="isPreviewMode"
              :isBlank="isBlank"
              @reloadInvoiceData="reloadInvoiceData"
            ></StockInvoiceRecipientIssuerData>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <StockInvoiceStakeholders
              v-if="_.size(invoice) > 0"
              :invoice="invoice"
              :isPreviewMode="isPreviewMode"
              @reloadInvoiceData="reloadInvoiceData"
            ></StockInvoiceStakeholders>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="8" offset-md="2">
            <StockInvoiceRegData
              :invoice.sync="invoice"
              :isPreviewMode="isPreviewMode"
            ></StockInvoiceRegData>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
.invoice_number {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
</style>
<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
//Stock Module
import { FETCH_STOCK_INVOICE } from "@/modules/stock/store/stock.module";
import StockInvoiceRecipientIssuerData from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceRecipientIssuerData.vue";
import StockInvoiceStakeholders from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholders.vue";
import StockInvoiceRegData from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceRegData.vue";

import { STOCK_WHOLESALE_INVOICE_PDF } from "@/modules/stock/store/stock.module";

import _ from "lodash";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
export default {
  name: "StockInvoiceShow",
  mixins: [permissionMixin],
  props: {
    isPageLoading: {
      type: Boolean,
      default: true
    },
    firstLoader: {
      type: Boolean,
      default: true
    },
    selectedInvoiceId: {
      type: Number,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      isBlank: false,
      invoicePaymentMethods: [],
      invoiceNoVatReasons: [],
      filtered: {
        invoicePaymentMethods: [],
        invoiceNoVatReasons: []
      },
      invoice: {}
    };
  },
  components: {
    StockInvoiceRecipientIssuerData,
    StockInvoiceStakeholders,
    StockInvoiceRegData
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Invoices", route: { name: "stock-invoices-list" } },
      { title: "Stock Invoice Show" }
    ]);
    vm.getInvoice();
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    tempFirstLoader: {
      get: function () {
        return this.firstLoader;
      },
      set: function (newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempIsPageLoading: {
      get: function () {
        return this.isPageLoading;
      },
      set: function (newValue) {
        this.$emit("update:isPageLoading", newValue);
      }
    },
    isPreviewMode() {
      return this.invoice.regAt !== null;
    }
  },
  methods: {
    getInvoice() {
      let vm = this;
      let id = vm.selectedInvoiceId;
      this.$store
        .dispatch(FETCH_STOCK_INVOICE, id)
        .then((data) => {
          vm.tempFirstLoader = false;
          vm.$nextTick(function () {
            vm.invoice = data.data;
            vm.isBlank =
              vm.invoice.summary["total"]["docTotalWithVatCurrency"] == 0;
            if (vm.tempIsPageLoading == true) {
              vm.tempIsPageLoading = false;
            }
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-items" });
          }
        });
    },
    reloadInvoiceData() {
      this.getInvoice();
    },
    getSelects() {
      let vm = this;
      let payload = {
        invoice_no_vat_reasons: {
          fields: ["id", "name"],
          search: ""
        },
        invoice_payment_methods: {
          fields: ["id", "description"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then((data) => {
          console.log(data);
          vm.invoiceNoVatReasons = data.data.invoice_no_vat_reasons;
          vm.invoicePaymentMethods = data.data.invoice_payment_methods;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    getInvoicePdf() {
      let vm = this;
      let params = {
        invoiceId: vm.selectedInvoiceId
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(STOCK_WHOLESALE_INVOICE_PDF, apiParams)
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function () {
            let blob = new Blob([data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "invoice-wholesale-" + vm.selectedInvoiceId;
            link.click();
            link.remove();
            vm.dialogPDF = false;
            if (vm.isPageLoading == true || vm.isPDFloading == true) {
              vm.isPageLoading = false;
              vm.isPDFloading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
