<template>
  <div>
    <StockInvoiceStakeholderSupplier
      v-if="_.size(invoice.summary) > 0 && _.size(invoice.summary.supplier) > 0"
      :supplier="invoice.summary.supplier"
      :invoiceId="invoice.id"
      :isPreviewMode="isPreviewMode"
      @reloadInvoiceData="reloadInvoiceData"
    ></StockInvoiceStakeholderSupplier>
    <StockInvoiceStakeholderTransport
      v-if="
        _.size(invoice.summary) > 0 && _.size(invoice.summary.transport) > 0
      "
      :transport="invoice.summary.transport"
      :invoiceId="invoice.id"
      :isPreviewMode="isPreviewMode"
      @reloadInvoiceData="reloadInvoiceData"
    ></StockInvoiceStakeholderTransport>
    <StockInvoiceStakeholderPallet
      v-if="_.size(invoice.summary) > 0 && _.size(invoice.summary.pallet) > 0"
      :pallet="invoice.summary.pallet"
      :invoiceId="invoice.id"
      :isPreviewMode="isPreviewMode"
      @reloadInvoiceData="reloadInvoiceData"
    ></StockInvoiceStakeholderPallet>
    <StockInvoiceStakeholderWholesale
      v-if="
        _.size(invoice.summary) > 0 && _.size(invoice.summary.wholesale) > 0
      "
      :wholesale="invoice.summary.wholesale"
      :invoice="invoice"
      :invoiceId="invoice.id"
      :isPreviewMode="isPreviewMode"
      @reloadInvoiceData="reloadInvoiceData"
    ></StockInvoiceStakeholderWholesale>

    <StockInvoiceStakeholderSupplier
      v-if="
        _.size(invoice.summary) > 0 &&
          _.size(invoice.summary.creditDebitAction) > 0
      "
      :supplier="invoice.summary.creditDebitAction"
      :invoiceId="invoice.id"
      :isPreviewMode="isPreviewMode"
      @reloadInvoiceData="reloadInvoiceData"
    ></StockInvoiceStakeholderSupplier>
    <hr />

    <v-col>
      <h4>
        {{ $t("TOTAL") }}
        <template v-if="invoice.customsId != null">{{
          $t("CUSTOMS_DOCUMENT")
        }}</template
        ><template v-else>{{ $t("INVOICE") }}</template>
      </h4>
      <StockInvoiceStakeholderDocumentsTotal
        :documentsTotal="invoice.summary.total"
      ></StockInvoiceStakeholderDocumentsTotal>
    </v-col>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General

import { mapGetters } from "vuex";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import StockInvoiceStakeholderSupplier from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderSupplier.vue";
import StockInvoiceStakeholderTransport from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderTransport.vue";
import StockInvoiceStakeholderPallet from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderPallet.vue";
import StockInvoiceStakeholderWholesale from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderWholesale.vue";
import StockInvoiceStakeholderDocumentsTotal from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderDocumentsTotal.vue";
//Stock Module
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholders",
  mixins: [permissionMixin],
  props: {
    invoice: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  components: {
    StockInvoiceStakeholderSupplier,
    StockInvoiceStakeholderTransport,
    StockInvoiceStakeholderPallet,
    StockInvoiceStakeholderWholesale,
    StockInvoiceStakeholderDocumentsTotal
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    tempData: {
      get: function() {
        return this.data;
      },
      set: function(newValue) {
        this.$emit("update:data", newValue);
      }
    }
  },
  methods: {
    reloadInvoiceData() {
      this.$emit("reloadInvoiceData");
    }
  }
};
</script>
