<template>
  <div>
    <v-simple-table
      ><thead>
        <tr>
          <th colspan="2">
            <h4>
              {{ heading }}
            </h4>
            <v-row>
              <v-col sm="8">
                <v-autocomplete
                  hide-details
                  v-if="isBlank"
                  :items="clients"
                  :label="$t('STOCK_WHOLESALE_CLIENTS')"
                  item-text="name"
                  item-value="id"
                  clearable
                  v-model="filters.clientId"
                ></v-autocomplete>
              </v-col>
              <v-col sm="4">
                <v-btn
                  v-if="isBlank"
                  class="mt-3"
                  color="success"
                  @click="updateRecipientId"
                >
                  {{ $t("SAVE") }}
                </v-btn>
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t("COMPANY_NAME") }}</td>
          <td>
            <template>
              <v-text-field
                :disabled="isPreviewMode"
                v-model="tempData.name"
                :label="$t('COMPANY_NAME')"
                hide-details
              ></v-text-field>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("COMPANY_OWNER") }}</td>
          <td>
            <template>
              <v-text-field
                :disabled="isPreviewMode"
                v-model="tempData.owner"
                :label="$t('COMPANY_OWNER')"
                hide-details
              ></v-text-field>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("COMPANY_ADDRESS") }}</td>
          <td>
            <template>
              <v-text-field
                :disabled="isPreviewMode"
                v-model="tempData.address"
                :label="$t('COMPANY_ADDRESS')"
                hide-details
              ></v-text-field>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("BULSTAT") }}</td>
          <td>
            <template>
              <v-text-field
                :disabled="isPreviewMode"
                v-model="tempData.bulstat"
                :label="$t('BULSTAT')"
                hide-details
              ></v-text-field>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("VAT_NUMBER") }}</td>
          <td>
            <template>
              <v-text-field
                :disabled="isPreviewMode"
                v-model="tempData.vatNumber"
                :label="$t('VAT_NUMBER')"
                hide-details
              ></v-text-field>
            </template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="text-right mt-5 mr-5">
      <v-btn v-if="!isPreviewMode" @click="save()">
        {{ $t("SAVE") }}
      </v-btn>
    </div>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General

import { mapGetters } from "vuex";

import {
  UPDATE_RECIPIENT_DATA,
  UPDATE_RECIPIENT_ID
} from "@/modules/stock/store/stock.module";
import { UPDATE_ISSUER_DATA } from "@/modules/stock/store/stock.module";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
//Stock Module
import _ from "lodash";
import { FETCH_CLIENTS_WITH_STORES } from "../../../../store/stock.module";
export default {
  name: "StockInvoiceRecipientIssuerData",
  mixins: [permissionMixin],
  props: {
    selectedInvoiceId: {
      type: Number,
      default: function() {
        return null;
      }
    },
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    heading: {
      type: String,
      default: function() {
        return "";
      }
    },
    source: {
      type: String,
      default: function() {
        return "issuer";
      }
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    isBlank: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  data() {
    return {
      invoice: {},
      clients: {},
      filters: {
        clientId: null
      }
    };
  },
  watch: {
    clientsParams(value) {
      let vm = this;
      vm.filters.clientId = null;
      vm.getClients();
    }
  },
  mounted() {
    this.getClients();
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    tempData: {
      get: function() {
        return this.data;
      },
      set: function(newValue) {
        this.$emit("update:data", newValue);
      }
    },
    params() {
      return {
        clientId: this.filters.clientId
      };
    }
  },
  methods: {
    save() {
      let vm = this;
      if (vm.source == "recipient") {
        vm.updateRecipientData();
      } else if (vm.source == "issuer") {
        vm.updateIssuerData();
      }
    },
    updateRecipientData() {
      let vm = this;
      const params = {
        id: vm.tempData.id,
        payload: vm.tempData
      };
      this.$store
        .dispatch(UPDATE_RECIPIENT_DATA, params)
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    updateRecipientId() {
      let vm = this;
      const params = {
        clientId: vm.filters.clientId,
        invoiceId: vm.selectedInvoiceId
      };
      this.$store
        .dispatch(UPDATE_RECIPIENT_ID, params)
        .then(data => {
          this.$emit("reloadInvoiceData");
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    updateIssuerData() {
      let vm = this;
      const params = {
        id: vm.tempData.id,
        payload: vm.tempData
      };
      this.$store
        .dispatch(UPDATE_ISSUER_DATA, params)
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },

    getClients() {
      let vm = this;
      let params = {};
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CLIENTS_WITH_STORES, apiParams)
        .then(data => {
          this.$set(vm, "clients", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
