<template>
  <div>
    <h4>{{ $t("DOCUMENTS") }} {{ stakeHolderName }}</h4>
    <v-simple-table
      ><thead>
        <tr>
          <th class="text-center">{{ $t("STOCK_ACTION_NUMBER") }}</th>
          <th class="text-center">{{ $t("SERVICE_NAME") }}</th>
          <th class="text-center">{{ $t("QTY") }}</th>
          <th class="text-center">{{ $t("UNIT_VALUE_TYPE") }}</th>
          <th class="text-center">{{ $t("SINGLE_PRICE") }}</th>
          <th class="text-center">{{ $t("TOTAL_PRICE") }}</th>
          <th class="text-center">{{ $t("CURRENCY_CODE") }}</th>
          <th class="text-center">{{ $t("EXCHANGE_RATE") }}</th>
          <th class="text-center">{{ $t("TOTAL_PRICE_BGN") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(singleItem, singleItemIndex) in stockDocData.list"
          v-bind:key="'singleItem' + singleItemIndex"
        >
          <td>
            <router-link
              :to="{
                name: 'stock-action-review',
                params: { id: singleItem.stockActionId }
              }"
            >
              {{ singleItem.stockActionId }}
            </router-link>
            <v-btn
              v-if="!isPreviewMode"
              class="mx-2"
              icon
              x-small
              color="red"
              @click="deleteItem(singleItem.stakeholderId, singleItem.source)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>
            {{ stakeHolderName }} - {{ singleItem.data.docNum }} ({{
              singleItem.data.docDate
            }})
          </td>
          <td class="text-right">1</td>
          <td class="text-right"></td>
          <td class="text-right">
            {{ singleItem.data.docTotalCurrency }}
          </td>
          <td class="text-right">
            {{ singleItem.data.docTotalCurrency }}
          </td>
          <td>
            {{ singleItem.data.currencyCode }}
          </td>
          <td class="text-right">
            {{ singleItem.data.docCurrencyExchangeRate }}
          </td>
          <td class="text-right">
            {{ singleItem.data.docTotal }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <hr />

    <h4>{{ $t("TOTAL") }} {{ $t("DOCUMENTS") }} {{ stakeHolderName }}</h4>
    <StockInvoiceStakeholderDocumentsTotal
      :documentsTotal="stockDocData.total"
    ></StockInvoiceStakeholderDocumentsTotal>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
td,
th {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
<script>
//General

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import StockInvoiceStakeholderDocumentsTotal from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderDocumentsTotal.vue";
import { REMOVE_DOCUMENT_FROM_INVOICE } from "@/modules/stock/store/stock.module";
//Stock Module
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholderDocuments",
  mixins: [permissionMixin],
  props: {
    stockDocData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    stakeHolderName: {
      type: String,
      default: function() {
        return "";
      }
    },
    source: {
      type: String,
      default: function() {
        return "";
      }
    },
    invoiceId: {
      type: Number,
      default: 0
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  data() {
    return {};
  },
  components: {
    StockInvoiceStakeholderDocumentsTotal
  },
  mounted() {},
  computed: {},
  methods: {
    deleteItem(stakeholderId, source) {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stakeholderId: stakeholderId,
            source: source
          };
          const params = {
            id: vm.invoiceId,
            payload: payload
          };
          vm.$store
            .dispatch(REMOVE_DOCUMENT_FROM_INVOICE, params)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.$emit("reloadInvoiceData");
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    }
  }
};
</script>
