<template>
  <div>
    <v-col>
      <template>
        <StockInvoiceStakeholderItems
          :items="supplier.items"
        ></StockInvoiceStakeholderItems>

        <hr />

        <StockInvoiceStakeholderDocuments
          :stockDocData="supplier.stockDocData"
          :stakeHolderName="$t('SUPPLIER')"
          :source="'supplier'"
          :invoiceId="invoiceId"
          :isPreviewMode="isPreviewMode"
          @reloadInvoiceData="reloadInvoiceData"
        ></StockInvoiceStakeholderDocuments>
      </template>
    </v-col>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import StockInvoiceStakeholderItems from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderItems.vue";
import StockInvoiceStakeholderDocuments from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderDocuments.vue";
//Stock Module
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholderSupplier",
  mixins: [permissionMixin],
  props: {
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    invoiceId: {
      type: Number,
      default: 0
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  components: {
    StockInvoiceStakeholderItems,
    StockInvoiceStakeholderDocuments
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    reloadInvoiceData() {
      this.$emit("reloadInvoiceData");
    }
  }
};
</script>
