<template>
  <div>
    <template>
      <v-row v-if="tempInvoice.customsId != null">
        <v-col class="ml-3">
          <v-text-field
            v-model="tempInvoice.netValue"
            :label="$t('NET_VALUE')"
            hide-details
            type="number"
            :step="0.01"
            min="0"
            @blur="filterGrossValue()"
          ></v-text-field>
        </v-col>
        <v-col class="mr-3">
          <v-text-field
            v-model="tempInvoice.vatValue"
            :label="$t('VAT_VALUE')"
            hide-details
            type="number"
            :step="0.01"
            min="0"
            @blur="filterGrossValue()"
          ></v-text-field>
        </v-col>
        <v-col class="mr-3">
          <v-text-field
            v-model="tempInvoice.grossValue"
            :disabled="true"
            :label="$t('GROSS_VALUE')"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-7">
        <v-col
          v-if="
            tempInvoice.stockInvoiceDocumentTypeId == 1 ||
              tempInvoice.stockInvoiceDocumentTypeId == 4
          "
        >
          <v-checkbox
            v-model="tempInvoice.isDebit"
            :label="`Дебитно известие?`"
          ></v-checkbox>
        </v-col>
        <v-col class="ml-3">
          <v-select
            :items="invoiceNoVatReasons"
            :label="$t('NO_VAT_REASON')"
            item-text="name"
            item-value="id"
            hide-details
            clearable
            v-model="tempInvoice.invoiceNoVatReasonId"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="invoicePaymentMethods"
            :label="$t('PAYMENT_METHOD')"
            item-text="description"
            item-value="id"
            hide-details
            clearable
            v-model="tempInvoice.invoicePaymentMethodId"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="tempInvoice.netWeight"
            :label="$t('NET_WEIGHT')"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col class="mr-3">
          <v-text-field
            v-model="tempInvoice.grossWeight"
            :label="$t('GROSS_WEIGHT')"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-7">
        <v-col>
          <FormDatepickerBase
            :date.sync="tempInvoice.dueDate"
            :type="'text'"
            :clearable="true"
            :i18n="{
              label: 'DUE_DATE',
              placeholder: $t('DUE_DATE')
            }"
          ></FormDatepickerBase>
        </v-col>
        <v-col class="mr-3">
          <FormDatepickerBase
            :date.sync="tempInvoice.eventPaymentDate"
            :type="'text'"
            :clearable="true"
            :i18n="{
              label: 'PAYMENT_DATE',
              placeholder: $t('PAYMENT_DATE')
            }"
          ></FormDatepickerBase>
        </v-col>
        <v-col class="mr-3">
          <FormDatepickerBase
            :date.sync="tempInvoice.workDate"
            :type="'text'"
            :clearable="true"
            :i18n="{
              label: 'WORK_DATE',
              placeholder: $t('WORK_DATE')
            }"
          ></FormDatepickerBase>
        </v-col>
      </v-row>
    </template>
    <template v-if="!isPreviewMode">
      <v-col
        v-if="
          tempInvoice.stockInvoiceTypeId == 2 ||
            tempInvoice.stockInvoiceTypeId == 3 ||
            tempInvoice.stockInvoiceTypeId == 4
        "
      >
        <v-text-field
          v-model="tempInvoice.invoiceNumber"
          :label="$t('NUMBER')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        v-if="
          tempInvoice.stockInvoiceTypeId == 1 ||
            tempInvoice.stockInvoiceTypeId == 2 ||
            tempInvoice.stockInvoiceTypeId == 3 ||
            tempInvoice.stockInvoiceTypeId == 4
        "
      >
        <FormDatepickerBase
          :type="'text'"
          :clearable="true"
          :max="maxDate"
          :i18n="{
            label: 'ISSUE_DATE',
            placeholder: $t('ISSUE_DATE')
          }"
          :date.sync="tempInvoice.issueDate"
        ></FormDatepickerBase>
      </v-col>
      <v-col>
        <v-text-field
          v-model="tempInvoice.regNum"
          :label="$t('REG_NUM')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="tempInvoice.regDescription"
          :label="$t('REG_DESCRIPTION')"
          hide-details
        ></v-text-field>
      </v-col>
      <div class="text-left float-left mt-5 mr-5">
        <v-btn
          :disabled="isLoadingStock"
          @click="save(0)"
          color="primary"
          x-large
        >
          {{ $t("BUTTON_UPDATE_DATA") }}
        </v-btn>
      </div>
      <div class="text-left float-left mt-5 mr-5">
        <v-btn
          :disabled="isLoadingStock"
          @click="save(2)"
          color="warning"
          x-large
        >
          {{ $t("BUTTON_SAVE_WITHOUT_AJUR") }}
        </v-btn>
      </div>
      <div class="text-right mt-5 mr-5">
        <v-btn
          :disabled="isLoadingStock"
          v-if="!isPreviewMode"
          @click="save(1)"
          color="success"
          x-large
        >
          {{ $t("BUTTON_ACCOUNT") }}
        </v-btn>
      </div>
    </template>
    <template v-if="isPreviewMode">
      <v-col v-if="tempInvoice.stockInvoiceTypeId == 2">
        <v-text-field
          :disabled="true"
          v-model="tempInvoice.invoiceNumber"
          :label="$t('INVOICE_NUMBER')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :disabled="true"
          v-model="tempInvoice.regNum"
          :label="$t('REG_NUM')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :disabled="true"
          v-model="tempInvoice.regDescription"
          :label="$t('REG_DESCRIPTION')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :disabled="true"
          v-model="tempInvoice.regAt"
          :label="$t('REG_AT')"
          hide-details
        ></v-text-field>
      </v-col>
      <div class="text-left float-left mt-5 mr-5">
        <!--        <v-btn @click="save(0)" color="teal-lighten-4" x-large>-->
        <!--          {{ $t("BUTTON_UPDATE_DATA") }}-->
        <!--        </v-btn>-->
      </div>
      <div class="text-right mt-5 mr-5">
        <v-btn v-if="isPreviewMode" @click="edit()" color="error" x-large>
          {{ $t("BUTTON_EDIT_ACCOUNT") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General

import { mapGetters } from "vuex";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { REGISTER_INVOICE } from "@/modules/stock/store/stock.module";
import { UNREGISTER_INVOICE } from "@/modules/stock/store/stock.module";
//Stock Module
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";

import moment from "moment";
export default {
  name: "StockInvoiceRegData",
  components: { FormDatepickerBase },
  mixins: [permissionMixin],
  props: {
    invoice: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  data() {
    return {
      invoicePaymentMethods: [],
      invoiceNoVatReasons: []
    };
  },
  mounted() {
    let vm = this;
    vm.getSelects();
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    tempInvoice: {
      get: function() {
        return this.invoice;
      },
      set: function(newValue) {
        this.$emit("update:invoice", newValue);
      }
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    save(isAccounting) {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {};
          payload = {
            invoicePaymentMethods:
              vm.tempInvoice.invoicePaymentMethodId ?? null,
            invoiceNoVatReasons: vm.tempInvoice.invoiceNoVatReasonId ?? null,
            eventPaymentDate: vm.tempInvoice.eventPaymentDate,
            dueDate: vm.tempInvoice.dueDate,
            workDate: vm.tempInvoice.workDate,
            netWeight: vm.tempInvoice.netWeight,
            grossWeight: vm.tempInvoice.grossWeight,
            isAccounting: isAccounting,
            invoiceNumber: vm.tempInvoice.invoiceNumber,
            issueDate: vm.tempInvoice.issueDate,
            netValue: vm.tempInvoice.netValue,
            vatValue: vm.tempInvoice.vatValue,
            grossValue: vm.tempInvoice.grossValue,
            isDebit: vm.tempInvoice.isDebit
          };
          if (isAccounting == 1 || isAccounting == 2) {
            payload.regNum = vm.tempInvoice.regNum;
            payload.regDescription = vm.tempInvoice.regDescription;
          }
          const params = {
            id: vm.tempInvoice.id,
            payload: payload
          };
          vm.$store
            .dispatch(REGISTER_INVOICE, params)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.tempInvoice = data.data;
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    edit() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {};
          const params = {
            id: vm.tempInvoice.id,
            payload: payload
          };
          vm.$store
            .dispatch(UNREGISTER_INVOICE, params)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.tempInvoice = data.data;
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    getSelects() {
      let vm = this;
      let payload = {
        invoice_no_vat_reasons: {
          fields: ["id", "name"],
          search: ""
        },
        invoice_payment_methods: {
          fields: ["id", "description"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.invoiceNoVatReasons = data.data.invoice_no_vat_reasons;
          vm.invoicePaymentMethods = data.data.invoice_payment_methods;
        })
        .catch(response => {
          console.log(response);
        });
    },

    filterGrossValue() {
      let vm = this;
      vm.tempInvoice.grossValue =
        parseFloat(vm.tempInvoice.netValue) +
        parseFloat(vm.tempInvoice.vatValue);

      vm.tempInvoice.netValue = this.$options.filters.formatBalance(
        vm.tempInvoice.netValue
      );
      vm.tempInvoice.vatValue = this.$options.filters.formatBalance(
        vm.tempInvoice.vatValue
      );
      vm.tempInvoice.grossValue = this.$options.filters.formatBalance(
        vm.tempInvoice.grossValue
      );
    }
  }
};
</script>
