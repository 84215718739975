<template>
  <div>
    <v-row>
      <v-col sm="6">
        <v-simple-table
          ><thead>
            <tr>
              <th colspan="2">
                {{ $t("TOTAL_CURRENCY") }} {{ documentsTotal.currencyCode }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t("TOTAL_WITHOUT_VAT_CURRENCY") }}</td>
              <td class="text-right">
                {{ documentsTotal.docTotalCurrency | formatBalance }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("TOTAL_VAT_CURRENCY") }}</td>
              <td class="text-right">
                {{ documentsTotal.docVatCurrency | formatBalance }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("TOTAL_WITH_VAT_CURRENCY") }}</td>
              <td class="text-right">
                {{ documentsTotal.docTotalWithVatCurrency | formatBalance }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col sm="6">
        <v-simple-table
          ><thead>
            <tr>
              <th colspan="2">{{ $t("TOTAL") }} BGN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t("TOTAL_WITHOUT_VAT") }}</td>
              <td class="text-right">
                {{ documentsTotal.docTotal | formatBalance }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("TOTAL_VAT") }}</td>
              <td class="text-right">
                {{ documentsTotal.docVat | formatBalance }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("TOTAL_WITH_VAT") }}</td>
              <td class="text-right">
                {{ documentsTotal.docTotalWithVat | formatBalance }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
td,
th {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
<script>
//General

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
//Stock Module
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholderDocumentsTotal",
  mixins: [permissionMixin],
  props: {
    documentsTotal: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>
