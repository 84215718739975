var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm._.size(_vm.invoice.summary) > 0 && _vm._.size(_vm.invoice.summary.supplier) > 0)?_c('StockInvoiceStakeholderSupplier',{attrs:{"supplier":_vm.invoice.summary.supplier,"invoiceId":_vm.invoice.id,"isPreviewMode":_vm.isPreviewMode},on:{"reloadInvoiceData":_vm.reloadInvoiceData}}):_vm._e(),(
      _vm._.size(_vm.invoice.summary) > 0 && _vm._.size(_vm.invoice.summary.transport) > 0
    )?_c('StockInvoiceStakeholderTransport',{attrs:{"transport":_vm.invoice.summary.transport,"invoiceId":_vm.invoice.id,"isPreviewMode":_vm.isPreviewMode},on:{"reloadInvoiceData":_vm.reloadInvoiceData}}):_vm._e(),(_vm._.size(_vm.invoice.summary) > 0 && _vm._.size(_vm.invoice.summary.pallet) > 0)?_c('StockInvoiceStakeholderPallet',{attrs:{"pallet":_vm.invoice.summary.pallet,"invoiceId":_vm.invoice.id,"isPreviewMode":_vm.isPreviewMode},on:{"reloadInvoiceData":_vm.reloadInvoiceData}}):_vm._e(),(
      _vm._.size(_vm.invoice.summary) > 0 && _vm._.size(_vm.invoice.summary.wholesale) > 0
    )?_c('StockInvoiceStakeholderWholesale',{attrs:{"wholesale":_vm.invoice.summary.wholesale,"invoice":_vm.invoice,"invoiceId":_vm.invoice.id,"isPreviewMode":_vm.isPreviewMode},on:{"reloadInvoiceData":_vm.reloadInvoiceData}}):_vm._e(),(
      _vm._.size(_vm.invoice.summary) > 0 &&
        _vm._.size(_vm.invoice.summary.creditDebitAction) > 0
    )?_c('StockInvoiceStakeholderSupplier',{attrs:{"supplier":_vm.invoice.summary.creditDebitAction,"invoiceId":_vm.invoice.id,"isPreviewMode":_vm.isPreviewMode},on:{"reloadInvoiceData":_vm.reloadInvoiceData}}):_vm._e(),_c('hr'),_c('v-col',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("TOTAL"))+" "),(_vm.invoice.customsId != null)?[_vm._v(_vm._s(_vm.$t("CUSTOMS_DOCUMENT")))]:[_vm._v(_vm._s(_vm.$t("INVOICE")))]],2),_c('StockInvoiceStakeholderDocumentsTotal',{attrs:{"documentsTotal":_vm.invoice.summary.total}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }