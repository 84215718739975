<template>
  <div>
    <v-col>
      <template>
        <StockInvoiceStakeholderItems
          :items="wholesale.items"
          :stockDocTotal="wholesale.stockDocData.total"
          @updatePrice="updatePrice"
        ></StockInvoiceStakeholderItems>
        <hr />

        <StockInvoiceStakeholderDocuments
          :stockDocData="wholesale.stockDocData"
          :stakeHolderName="$t('WHOLESALES')"
          :source="'wholesale'"
          :invoiceId="invoiceId"
          :isPreviewMode="isPreviewMode"
          @reloadInvoiceData="reloadInvoiceData"
        ></StockInvoiceStakeholderDocuments>

        <hr />
        <v-col>
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="invoice.additionalService"
                :label="$t('ADDITIONAL_SERVICE')"
                :disabled="isPreviewMode"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :disabled="isPreviewMode"
                v-model="invoice.additionalServiceCost"
                type="number"
                step="0.01"
                :label="$t('ADDITIONAL_SERVICE_COST')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn
                :disabled="isPreviewMode"
                class="mx-2"
                fab
                dark
                x-medium
                color="#1BC5BD"
                @click="updateInvoiceAddtionalService"
              >
                <v-icon dark>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-col>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import StockInvoiceStakeholderItems from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderItems.vue";
import StockInvoiceStakeholderDocuments from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceStakeholderDocuments.vue";
import {
  UPDATE_WHOLESALE_ITEM,
  UPDATE_INVOICE
} from "@/modules/stock/store/stock.module";
//Stock Module
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholderWholesale",
  mixins: [permissionMixin],
  props: {
    wholesale: {
      type: Object,
      default: function() {
        return {};
      }
    },
    invoice: {
      type: Object,
      default: function() {
        return {};
      }
    },
    invoiceId: {
      type: Number,
      default: 0
    },
    isPreviewMode: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  components: {
    StockInvoiceStakeholderItems,
    StockInvoiceStakeholderDocuments
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    updatePrice({ item, exchangeRate, singlePrice }) {
      if (singlePrice) {
        this.filterSinglePriceWholesale(item);
      } else {
        this.filterTotalPriceWholesale(item);
      }
      this.updateWholesaleItem(item, exchangeRate);
    },
    updateInvoiceAddtionalService() {
      let vm = this;
      let params = {
        additionalService: vm.invoice.additionalService,
        additionalServiceCost: vm.invoice.additionalServiceCost
      };
      vm.$store
        .dispatch(UPDATE_INVOICE, {
          id: vm.invoice.id,
          payload: params
        })
        .then(data => {
          vm.reloadInvoiceData();
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    updateWholesaleItem(item, exchangeRate) {
      let vm = this;

      let totalPrice =
        parseFloat(item.unitValue) *
        parseFloat(item.singlePriceCurrency) *
        parseFloat(exchangeRate);

      let singlePrice = totalPrice / parseFloat(item.unitValue);

      item.singlePrice = this.$options.filters.formatBalance(singlePrice);
      item.totalPrice = this.$options.filters.formatBalance(totalPrice);

      vm.$store
        .dispatch(UPDATE_WHOLESALE_ITEM, {
          wholeSaleItem: item
        })
        .then(data => {
          vm.reloadInvoiceData();
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    reloadInvoiceData() {
      this.$emit("reloadInvoiceData");
    },
    filterSinglePriceWholesale(item) {
      item.singlePriceCurrency = this.$options.filters.formatDecimal6(
        item.singlePriceCurrency
      );
      this.updateItemTotalPriceWholesale(item);
    },
    filterTotalPriceWholesale(item) {
      item.totalPriceCurrency = this.$options.filters.formatBalance(
        item.totalPriceCurrency
      );
      this.updateItemSinglePriceWholesale(item);
    },
    updateItemTotalPriceWholesale(item) {
      let value =
        parseFloat(item.unitValue) * parseFloat(item.singlePriceCurrency);
      item.totalPriceCurrency = this.$options.filters.formatBalance(value);
    },
    updateItemSinglePriceWholesale(item) {
      let value =
        parseFloat(item.totalPriceCurrency) / parseFloat(item.unitValue);
      item.singlePriceCurrency = this.$options.filters.formatDecimal6(value);
    }
  }
};
</script>
