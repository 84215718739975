<template>
  <div>
    <h4>
      {{ $t("ITEMS_LIST") }}
    </h4>
    <v-sheet class="pa-5">
      <div style="display: flex">
        <v-switch v-model="showItems" inset :label="$t('PRODUCTS')"></v-switch>
        <v-switch
          class="ml-5"
          v-model="showTotal"
          inset
          :label="$t('TOTAL')"
        ></v-switch>
        <v-btn
          v-if="showTotal"
          color="btn-success"
          class="btn btn-light-success font-weight-bolder mr-2 mt-3 ml-5"
          @click="getInvoiceOverallExcel()"
        >
          <i class="flaticon-download"></i>
          {{ $t("DOWNLOAD_TOTAL_EXCEL") }}
        </v-btn>
      </div>
    </v-sheet>
    <v-simple-table
      ><thead>
        <tr>
          <th class="text-center">{{ $t("STOCK_ACTION_NUMBER") }}</th>
          <th class="text-center">{{ $t("CODE") }}</th>
          <th class="text-center">{{ $t("NAME_LOT") }}</th>
          <th class="text-center">{{ $t("QTY") }}</th>
          <th class="text-center">{{ $t("UNIT_VALUE_TYPE") }}</th>
          <th class="text-center">{{ $t("SINGLE_PRICE") }}</th>
          <th class="text-center">{{ $t("TOTAL_PRICE") }}</th>
          <th class="text-center">{{ $t("SINGLE_PRICE_WHOLESALE") }}</th>
          <th class="text-center">{{ $t("TOTAL_PRICE_WHOLESALE") }}</th>
          <th class="text-center">{{ $t("CURRENCY_CODE") }}</th>
          <th class="text-center">{{ $t("EXCHANGE_RATE") }}</th>
          <th class="text-center">{{ $t("TOTAL_PRICE_BGN") }}</th>
        </tr>
      </thead>
      <tbody v-for="(item, index) in items" v-bind:key="'item' + index">
        <template v-if="showItems">
          <tr
            v-for="(singleItem, singleItemIndex) in item.list"
            v-bind:key="'singleItem' + singleItemIndex"
            :ref="'item_row_' + singleItem.item.id"
          >
            <td>
              <router-link
                :to="{
                  name: 'stock-action-review',
                  params: { id: singleItem.stockActionId }
                }"
              >
                {{ singleItem.stockActionId }}
              </router-link>
            </td>
            <td>
              {{ singleItem.item.itemCode }}
            </td>
            <td>
              {{ singleItem.item.name }}<br />{{ singleItem.item.lot }} -
              {{ singleItem.item.lotExpDate }}
            </td>
            <td class="text-right">
              {{ singleItem.item.unitValue }}
            </td>
            <td class="text-right">
              {{ singleItem.item.unitShortName }}
            </td>
            <td class="text-right">
              {{ singleItem.item.warehouseItemPrice }}
            </td>
            <td class="text-right">
              {{ singleItem.item.warehouseItemTotalPrice }}
            </td>
            <td class="text-right">
              <v-text-field
                type="text"
                placholder="123"
                hide-details
                v-model="singleItem.item.singlePriceCurrency"
                @blur="filterSinglePriceWholesale(singleItem.item)"
                v-on:keyup.enter="
                  updatePrice(
                    singleItem.item,
                    singleItem.docCurrencyExchangeRate,
                    true
                  )
                "
              ></v-text-field>
            </td>
            <td class="text-right">
              <v-text-field
                type="text"
                placholder="123"
                hide-details
                v-model="singleItem.item.totalPriceCurrency"
                @blur="filterTotalPriceWholesale(singleItem.item)"
                v-on:keyup.enter="
                  updatePrice(
                    singleItem.item,
                    singleItem.docCurrencyExchangeRate,
                    false
                  )
                "
              ></v-text-field>
            </td>
            <td>
              {{ singleItem.currencyCode }}
            </td>
            <td class="text-right">
              {{ singleItem.docCurrencyExchangeRate }}
            </td>
            <td class="text-right">
              {{ singleItem.item.totalPrice }}
            </td>
            <td>
              <v-btn
                class="mx-2"
                fab
                dark
                x-small
                color="#1BC5BD"
                @click="
                  updatePrice(
                    singleItem.item,
                    singleItem.docCurrencyExchangeRate,
                    false
                  )
                "
              >
                <v-icon dark>mdi-content-save</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <tr v-if="showTotal">
          <td>{{ $t("TOTAL") }}</td>
          <td>{{ item.list[0].item.itemCode }}</td>
          <td>{{ item.product.name }}</td>
          <td class="text-right">
            {{ item.total.totalUnitValue }}
          </td>
          <td class="text-right">{{ item.product.unitShortName }}</td>
          <td></td>
          <td class="text-right">
            {{ item.total.totalWarehousePrice | formatBalance }}
          </td>
          <td></td>
          <td class="text-right">
            {{ item.total.totalPriceCurrency | formatBalance }}
          </td>
          <td>{{ item.total.currencyCode }}</td>
          <td></td>
          <td class="text-right">
            {{ item.total.totalPrice | formatBalance }}
          </td>
        </tr>
      </tbody>
      <tbody v-if="showTotal || showItems">
        <tr>
          <td>{{ $t("TOTAL_WAREHOUSE_PRICE") }}</td>
          <td colspan="6" class="text-right">
            {{ warehouseTotalPrice | formatBalance }}
          </td>
          <td colspan="2" class="text-right">
            {{ stockDocTotal.docTotalCurrency | formatBalance }}
          </td>
          <td colspan="3" class="text-right">
            {{ stockDocTotal.docTotal | formatBalance }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
td,
th {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
<script>
//General

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
//Stock Module
import { STOCK_WHOLESALE_OVERALL_EXCEL } from "@/modules/stock/store/stock.module";
import _ from "lodash";
export default {
  name: "StockInvoiceStakeholderItems",
  mixins: [permissionMixin],
  props: {
    items: {
      type: Object,
      default: function() {
        return {};
      }
    },
    stockDocTotal: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      showTotal: false,
      showItems: false
    };
  },
  mounted() {},
  computed: {
    warehouseTotalPrice() {
      let totalPrice = 0;
      _.forEach(this.items, function(item) {
        totalPrice += parseFloat(item.total.totalWarehousePrice);
      });
      return totalPrice;
    }
  },
  methods: {
    updatePrice(item, docCurrencyExchangeRate, singlePrice) {
      let itemRow = this.$refs["item_row_" + item.id][0];
      itemRow.classList.add("invoice-updated-input");
      this.$emit("updatePrice", {
        item: item,
        exchangeRate: docCurrencyExchangeRate,
        singlePrice: singlePrice
      });
    },
    getInvoiceOverallExcel() {
      let vm = this;

      this.$store
        .dispatch(STOCK_WHOLESALE_OVERALL_EXCEL, vm.items)
        .then(data => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Invoice-Wholesale-Overall-Excel.xlsx";
            link.click();
            link.remove();
            if (vm.isPageLoading == true || vm.isXmlMzLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    filterSinglePriceWholesale(item) {
      item.singlePriceCurrency = this.$options.filters.formatDecimal6(
        item.singlePriceCurrency
      );
      this.updateItemTotalPriceWholesale(item);
    },
    filterTotalPriceWholesale(item) {
      item.totalPriceCurrency = this.$options.filters.formatBalance(
        item.totalPriceCurrency
      );
      this.updateItemSinglePriceWholesale(item);
    },
    updateItemTotalPriceWholesale(item) {
      let value =
        parseFloat(item.unitValue) * parseFloat(item.singlePriceCurrency);
      item.totalPriceCurrency = this.$options.filters.formatBalance(value);
    },
    updateItemSinglePriceWholesale(item) {
      let value =
        parseFloat(item.totalPriceCurrency) / parseFloat(item.unitValue);
      item.singlePriceCurrency = this.$options.filters.formatDecimal6(value);
    }
  }
};
</script>
